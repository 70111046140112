import React, { Fragment } from "react"
import styles from "./footer.module.css"

import { SimpleLayout } from "../components/layout"

import LogoBlack from "./logo-black"

const Footer = props => (
  <Fragment>
    <SimpleLayout style={{ background: "white" }}>
      <hr />
      <div className={styles.footer}>
        <div className={styles.footerLeft}>
          <div style={{ marginRight: "20px" }}>
            <LogoBlack />
          </div>
          <div className={styles.footerItem}>
            <h2>Contact</h2>
            <a href="mailto:hello@dualgoodhealth.com">
              hello@dualgoodhealth.com
            </a>
          </div>
          <div className={styles.footerItem}>
            <h2>Information</h2>
            <a href="https://blog.dualgoodhealth.com/">Blog</a>
            <a href="mailto:hello@dualgoodhealth.com">Press</a>
            <a href="mailto:morgan@dualgoodhealth.com">Technical support</a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.dropbox.com/s/wlrpiz6kgcgvtnk/Privacy%20policy%20%28DGH%29_v1.doc?dl=0"
            >
              Privacy policy
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.dropbox.com/s/8w8vgy5ryn0h8ty/Dual%20Good%20Health%20End%20User%20License%20Agreement%20%28EULA%29.docx?dl=0"
            >
              Terms & conditions
            </a>
          </div>
          <div className={styles.footerItem}>
            <h2>Follow us</h2>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/dualgoodhealth/"
            >
              Facebook
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/dualgoodhealth/"
            >
              Instagram
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/dualgoodhealth"
            >
              Twitter
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/11074598"
            >
              LinkedIn
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/channel/UC0g1qFICppnO9st75dA_OLQ?view_as=subscriber"
            >
              Youtube
            </a>
          </div>
        </div>
        <div className={styles.footerItem} style={{ marginRight: "0" }}>
          <p>© Dual Good Health Limited 2019</p>
        </div>
      </div>
    </SimpleLayout>
  </Fragment>
)

export const FooterWrapper = props => (
  <Fragment>
    <div className={styles.content}>{props.children}</div>
    <Footer />
  </Fragment>
)

export default Footer
