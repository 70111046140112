import React from "react"
import styles from "./connected.module.css"
import addToMailchimp from "gatsby-plugin-mailchimp"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class Connected extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      message: "",
    }
  }

  handleChange = e =>
    this.setState({
      [e.target.name]: e.target.value,
    })

  handleSubmitForm = e => {
    console.log(this.state)
    e.preventDefault()
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...this.state }),
    })
      .then(() => this.setState({ formPost: true }))
      .catch(error => alert(error))
  }

  handleSubmitMailchimp = async e => {
    // console.log(this.state)
    e.preventDefault()

    try {
      const result = await addToMailchimp(this.state.email)

      if (result.result === "success") {
        this.setState({ message: result.msg, email: "" })
        this.email.value = ""
      } else {
        this.setState({ message: result.msg, email: "" })
      }
      console.log(result)
    } catch (error) {
      this.setState({ message: "Error!" })
    }

    // const result = await addToMailchimp(this.state.email, {
    //   FName: "aaaa",
    //   LName: "bbb",
    // })
    // I recommend setting `result` to React state
    // but you can do whatever you want
  }

  render() {
    return (
      <div className={styles.connected}>
        <div className={styles.dialog}>
          <h1>Stay connected</h1>
          <h2>Sign up to our newsletter to hear our latest news and updates</h2>
          <form
            onSubmit={this.handleSubmitMailchimp}
            name="contact"
            method="post"
            // action="/thanks"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="contact" />
            <p hidden>
              <label>
                Don’t fill this out:{" "}
                <input name="bot-field" onChange={this.handleChange} />
              </label>
            </p>
            <input
              ref={el => (this.email = el)}
              type="text"
              id="email"
              name="email"
              placeholder="Email"
              onChange={this.handleChange}
            />
            <div className={styles.buttoncontainer}>
              <input type="submit" value="SUBMIT" className={styles.button} />
            </div>
          </form>
          <p dangerouslySetInnerHTML={{ __html: this.state.message }} />
        </div>
      </div>
    )
  }
}

export default Connected
